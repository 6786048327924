/* ----------------- Table -------------------*/

.table td,
.table th {
  overflow: hidden;
  white-space: normal !important;
}

.table {
  table-layout: fixed;
}

@media screen and (max-width: 991px) {
  .table {
    table-layout:inherit
  } 
}

/* ---------------- Pagination ----------------- */

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

i.icon.feather.icon-settings {
  font-size: 22px;
}

.pcoded-header .dropdown .dropdown-toggle:after {
  display: none;
}

/* ---------------------- Form Control CSS ---------------------------------- */

input.form-control, textarea.form-control {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid black;
  box-shadow: 0px 4px 7px 4px #ededed;
}

.form-control:disabled {
  background-color: #f5f5f5;
  opacity: 1;
}